import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Weiss: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Weiss,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Weiß"
          d="M783.9,884.3c0,0.1,0,0.1-0.1,0.2c-1.2,3.9-3.5,7.4-6.6,10.1c-17.8,15.5-47.9,25.5-65.2,39.4l-22-26l-6-14 l14-2l24-34l-4-4l12-32c12,0.5,19.5,1.9,26.2,4.5c14.1,5.4,13.1,7.2,18.5,13.9c0.1,0.1,0.1,0.1,0.2,0.2c3.4,4.2,6.2,8.9,8.2,13.9 l0,0c1.2,3,2.1,6.1,2.5,9.2l0,0C786.6,870.7,786,877.7,783.9,884.3z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 733.867 879.1096)"
        >
          Weiß
        </text>
      </g>
    </>
  );
});
